.bubble {
  width: 140px;
  position: relative;
  cursor: pointer;
  box-shadow: 5px 0px #888887;
  border-radius: 10px;
  padding: 12px;
  bottom: 20px;
}
.bubble:before {
  content: '';
  position: absolute;
  top: -webkit-calc(100% - 10px); /*may require prefix for old browser support*/
  top: calc(100% - 10px); /*i.e. half the height*/
  left: 80px;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
  border-bottom: inherit;
  border-right: inherit;
  box-shadow: inherit;
}

.MuiStepper-root {
  padding: 0 !important;
}
