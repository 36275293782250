@import url('https://fonts.googleapis.com/css?family=Barlow&display=swap');

@import './config/colors.scss';
@import './config/media_queries.scss';

@font-face {
  font-family: 'AvanirBook';
  src: local('AvenirLTStd-Book'), url(../fonts/AvenirLTStd-Roman.otf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-Medium'), url(../fonts/Metropolis-Medium.otf) format('truetype');
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Metropolis';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  font-display: swap;
  background-color: white !important;
}

.slick-dots {
  bottom: 12px !important;
}

.slick-prev {
  left: 16px !important;
  z-index: 11 !important;
}

.slick-next {
  right: 22px !important;
  z-index: 11 !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 25px !important;
}

#root {
  // height: 100vh;
}

.picoz-container {
  padding: 15px 0;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: $blue;
  padding: 0.5em 0.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  display: flex;
  flex-direction: column;
}

/* Individual item */
.bm-item {
  display: inline-block;

  &:focus {
    outline: none;
  }
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.date-picker {
  display: block;
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  margin: 10px 0px;
  font-size: 15px;
  line-height: 1.428571429;
  color: #303133;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &:focus {
    outline: none;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.desktop {
  @media screen and (max-width: $break-ipad) {
    display: none !important;
  }
}

.mobile {
  @media screen and (min-width: $break-ipad) {
    display: none !important;
  }
}

.privacy_policy {
  background: white;
  padding: 1em;
  margin: 0 0 1em 0;

  p {
    font-weight: 300;
    font-size: 15px;
  }

  li {
    font-weight: 300;
    font-size: 15px;
  }

  h2 {
    font-size: 35px;
    font-weight: bold;
    margin-top: 20px;
  }

  h3 {
    font-size: 27px;
    font-weight: bold;
    margin-top: 20px;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
  }
}

.terms_and_conditions {
  background: white;
  padding: 1em;
  margin: 0 0 1em 0;

  p {
    font-weight: 300;
    font-size: 15px;
  }

  li {
    font-weight: 300;
    font-size: 15px;
  }

  .li-terms-and-conditions {
    font-weight: 600;
    font-size: 20px;
    margin: 15px 0;
  }

  .sub-li {
    font-size: 17px;
    margin: 10px 0;
  }

  h2 {
    font-size: 35px;
    font-weight: bold;
    margin-top: 20px;
  }

  h3 {
    font-size: 27px;
    font-weight: bold;
    margin-top: 20px;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
  }
}
